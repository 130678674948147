body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Label {
  font-size: 13px;
  margin: 0px !important;
}

.sticky-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

#root{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flex-fullheight{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.login-card{
    width: 500px;
    object-fit: contain;
}

.login-card .logo{
  display: flex;
  max-height: 220px;
}

.nav-logo{
  height: 60px;
}

.square-btn{
  width: 30px;
  height: 30px;
}

.rotate-30{
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.zui-table {
  border: none;
  border-right: solid 1px #DDEFEF;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
  background-color: #DDEFEF;
  border: none;
  color: #336B6B;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.zui-table tbody td {
  border-bottom: solid 1px #DDEFEF;
  color: #333;
  padding: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.zui-wrapper {
  position: relative;
}
.zui-scroller {
  margin-left: 141px;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
  width: 100%;
}
.zui-table .zui-sticky-col {
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 0;
  position: absolute;
  top: auto;
  width: 141px;
}

.flex-1 {
  flex: 1;
}

.width-100{
  width: 100%;
}